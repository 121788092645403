import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppContext } from '../state/StateProvider';
import { Container } from './Container';
import { LanguageSwitcher } from '../i18n/components/LanguageSwitcher';
import { Logo } from './Logo';
import styled from 'styled-components';
import { Colors } from '../constants/colors';
import { Sizes } from '../constants/sizes';
import { AnchoredLink } from './AnchoredLink';
import { Device } from '../constants/device';
import menuIcon from '../../assets/images/menu.svg';
import closeIcon from '../../assets/images/close.svg';

export const Header = (): JSX.Element | null => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const {
    state: { project, activeSections },
  } = useAppContext();
  const primaryLinks = activeSections.slice(0, 2);
  const secondaryLinks = activeSections.slice(2, activeSections.length);

  return (
    <FixedHeader>
      <Container>
        <HeaderContent>
          <Logo project={project} />
          <StyledNavigation>
            <PrimaryNavigation>
              {primaryLinks.map((section, key) => (
                <AnchoredLink key={key} anchor={section}>
                  <FormattedMessage id={'section.' + section} />
                </AnchoredLink>
              ))}
            </PrimaryNavigation>
            <SecondaryNavigation>
              <div>
                {secondaryLinks.map((section, key) => (
                  <AnchoredLink key={key} anchor={section}>
                    <FormattedMessage id={'section.' + section} />
                  </AnchoredLink>
                ))}
              </div>
              <LanguageSwitcher />
            </SecondaryNavigation>
          </StyledNavigation>

          <Burger onClick={() => setShowMobileMenu((show) => !show)}>
            <img src={showMobileMenu ? closeIcon : menuIcon} alt="Menu icon" />
          </Burger>

          {showMobileMenu && (
            <MobileMenu>
              <nav>
                {[...primaryLinks, ...secondaryLinks].map((section, key) => (
                  <AnchoredLink key={key} anchor={section} onClick={() => setShowMobileMenu(false)}>
                    <FormattedMessage id={'section.' + section} />
                  </AnchoredLink>
                ))}
              </nav>
              <LanguageSwitcher />
            </MobileMenu>
          )}
        </HeaderContent>
      </Container>
    </FixedHeader>
  );
};

const Burger = styled.button`
  width: 30px;
  height: 30px;
  padding: 0;
  background: none;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;

  ${Device.mobile} {
    height: 20px;
    width: 20px;
  }

  img {
    width: 100%;
  }
`;

const FixedHeader = styled.header`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
  padding-right: 20px;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  height: ${Sizes.HeaderHeight}px;

  ${Burger} {
    display: none;

    ${Device.laptop} {
      display: block;
    }
  }
`;

const StyledNavigation = styled.nav`
  flex: 1;
  display: flex;
  align-items: center;

  ${Device.laptop} {
    display: none;
  }

  a {
    padding: 0 40px;

    ${Device.desktop} {
      color: ${Colors.primary};
      padding: 0 25px;
    }
  }
`;

const PrimaryNavigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  padding-right: 30px;

  ${Device.desktop} {
    padding-right: 0;
  }

  a {
    color: ${Colors.primary};
  }
`;

const SecondaryNavigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: ${Sizes.JumbotronRightWidth}px;
  padding-right: 30px;

  ${Device.fullHdDown} {
    flex-basis: 590px;
  }

  ${Device.desktop} {
    flex-basis: auto;
    padding-right: 0;

    > div {
      margin-right: 30px;
      padding-right: 30px;
      border-right: 2px solid ${Colors.primary};
    }
  }
`;

const MobileMenu = styled.div`
  position: fixed;
  z-index: 100;
  background: ${Colors.white};
  width: 100vw;
  height: calc(100vh - ${Sizes.HeaderHeight}px);
  top: ${Sizes.HeaderHeight}px;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: ${Sizes.HeaderHeight}px;

  nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0;
  }

  a {
    color: ${Colors.primary};
    margin: 10px 0;
  }
`;
