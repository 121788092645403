import React, { ReactElement } from 'react';
import { Colors } from '../constants/colors';
import { ThemedLogo } from './ThemedLogo';

type Props = {
  className?: string;
  width?: number;
};

export const ThemedCompanyLogo = ({ className, width = 74 }: Props): ReactElement | null => (
  <ThemedLogo className={className} width={width}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 56.98">
      <g id="Vrstva_2" data-name="Vrstva 2">
        <g id="Vrstva_1-2" data-name="Vrstva 1">
          <path
            d="M66.17,6.94C50.23,8.44,39.1,15.28,33.09,27.41L0,5v50.6L2.61,55l.46-.1c14.85-3.27,25.46-10.34,31.66-21.18L69,57V6.69l-2.34.21ZM31,31.56l-.19.32a35,35,0,0,1-8.33,9.68V31l-4.32-2.16V44.58a41.75,41.75,0,0,1-4.31,2.27V25.54L9.55,23.26V48.6q-2.48.88-5.19,1.57H4.31V13.12L31.14,31.31ZM64.69,48.84l-28-19,.08-.17.15-.32a30.22,30.22,0,0,1,9-10.95v11l4.31,2.48V15.71a36,36,0,0,1,4.93-2.11V35.3l4.31,2.16V12.37q2.34-.54,4.89-.87l.35-.05Z"
            style={{ fill: Colors.primary }}
          />
        </g>
      </g>
    </svg>
  </ThemedLogo>
);
