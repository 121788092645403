import { css } from 'styled-components';

import RobotoSlabThinTTF from '../assets/fonts/RobotoSlab-Thin.ttf';
import RobotoSlabLightTTF from '../assets/fonts/RobotoSlab-Light.ttf';
import RobotoSlabRegularTTF from '../assets/fonts/RobotoSlab-Regular.ttf';
import RobotoSlabMediumTTF from '../assets/fonts/RobotoSlab-Medium.ttf';
import RobotoSlabSemiBoldTTF from '../assets/fonts/RobotoSlab-SemiBold.ttf';
import RobotoSlabBoldTTF from '../assets/fonts/RobotoSlab-Bold.ttf';

export const FontFace = css`
  @font-face {
    font-family: 'RobotoSlab';
    src: url(${RobotoSlabThinTTF}) format('truetype');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'RobotoSlab';
    src: url(${RobotoSlabLightTTF}) format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'RobotoSlab';
    src: url(${RobotoSlabRegularTTF}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'RobotoSlab';
    src: url(${RobotoSlabMediumTTF}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'RobotoSlab';
    src: url(${RobotoSlabSemiBoldTTF}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'RobotoSlab';
    src: url(${RobotoSlabBoldTTF}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }
`;
