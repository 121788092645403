import { CompanyInterface } from '../../model/CompanyInterface';
import { Company } from '../../model/Company';
import { AppLanguage } from '../../model/enums/AppLanguage';

export const translateCompany = (data: CompanyInterface, locale: string): Company => {
  if (locale === AppLanguage.Czech) {
    return {
      ...data,
      slogan: data.slogan_cs,
    };
  }

  return {
    ...data,
    slogan: data.slogan_en,
  };
};
