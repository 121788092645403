import React from 'react';
import { useAppContext } from '../state/StateProvider';
import { Container } from './Container';
import styled, { keyframes } from 'styled-components';
import { Colors } from '../constants/colors';
import { rgba } from 'polished';
import { OutlinedButton } from './buttons/OutlinedButton';
import { Button } from './buttons/Button';
import { FormattedMessage } from 'react-intl';
import { Sizes } from '../constants/sizes';
import { AnchoredLink } from './AnchoredLink';
import { Device } from '../constants/device';

export const Jumbotron = (): JSX.Element | null => {
  const {
    state: { project, activeSections, sourceBranch },
  } = useAppContext();
  const aboutSection = activeSections.find((section) => section === 'about');
  const demoOrDownloadSection = activeSections.find((section) => section === 'download' || section === 'demo');

  if (!project) {
    return null;
  }

  const imageSrc = `${process.env.REACT_APP_API_URL}/${sourceBranch}/images/screenshots/${process.env.REACT_APP_PROJECT}`;

  return (
    <StyledJumbotron>
      <Container>
        <JumbotronLeft>
          <h1>{project.name}</h1>
          <DividingLine />
          <p>{project.subtitle}</p>
          {(aboutSection || demoOrDownloadSection) && (
            <div className="buttons">
              {aboutSection && (
                <AnchoredLink anchor={aboutSection}>
                  <Button>
                    <FormattedMessage id={'section.' + aboutSection} />
                  </Button>
                </AnchoredLink>
              )}
              {demoOrDownloadSection && (
                <AnchoredLink anchor={demoOrDownloadSection}>
                  <OutlinedButton>
                    <FormattedMessage id={'section.' + demoOrDownloadSection} />
                  </OutlinedButton>
                </AnchoredLink>
              )}
            </div>
          )}
        </JumbotronLeft>
        <JumbotronRight>
          {project.mainScreenshots?.length && (
            <JumbotronPictures>
              {project.mainScreenshots.map((image, key) => (
                <JumbotronPicture key={key} src={imageSrc + '/' + image} />
              ))}
            </JumbotronPictures>
          )}
        </JumbotronRight>
      </Container>
    </StyledJumbotron>
  );
};

const StyledJumbotron = styled.div`
  width: 100%;
  height: min(800px, calc(100vh - 90px));
  background: ${Colors.white};
  margin-bottom: 120px;

  ${Device.desktop} {
    height: auto;
    padding-top: ${Sizes.HeaderHeight}px;
  }

  ${Container} {
    flex-direction: row;
    height: 100%;

    ${Device.desktop} {
      flex-direction: column;
    }
  }
`;

const JumbotronLeft = styled.div`
  flex: 1;
  padding-right: 200px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  ${Device.desktop} {
    padding-right: 0;
    margin: 40px 0 60px;
    align-items: center;
  }

  h1 {
    font-size: 58px;
    font-weight: 900;
    color: ${Colors.primaryDark};
    position: relative;
    ${Device.tablet} {
      font-size: 42px;
    }
  }

  p {
    margin: 0;
    font-size: 22px;
    color: ${Colors.primaryDark};

    ${Device.tablet} {
      font-size: 18px;
      text-align: center !important;
    }
  }

  .buttons {
    display: flex;
    margin: 50px -15px;

    ${Device.mobile} {
      flex-direction: column;
    }

    button {
      margin: 0 15px;

      ${Device.mobile} {
        margin-top: 15px;
      }
    }
  }
`;

const JumbotronRight = styled.div`
  flex-basis: ${Sizes.JumbotronRightWidth}px;
  background: ${Colors.primary};
  border-radius: 0 0 120px 120px;
  height: calc(100% + 120px);
  max-width: 45%;

  display: flex;
  align-items: center;

  position: relative;

  ${Device.desktop} {
    flex-basis: 0;
    background-image: none;
    border-radius: 0;
    margin-bottom: -110px;
    margin-left: -30px;
    margin-right: -30px;
    max-width: none;

    &::after {
      content: '';
      background-image: var(--gradient);
      width: calc(100% + 60px);
      height: 220px;
      top: 50%;
      left: -30px;
      transform: translateY(-110px);
      position: absolute;
      z-index: 1;
    }
  }
`;

const JumbotronPictures = styled.div`
  transform: translateX(-25%);
  padding-top: 100px;
  padding-bottom: 160px;
  display: flex;
  align-items: center;
  height: 95%;

  position: relative;
  z-index: 2;

  ${Device.fullHdDown} {
    padding-bottom: 100px;
  }

  ${Device.desktop} {
    width: 100%;
    justify-content: center;

    transform: none;
    padding-top: 0;
    padding-bottom: 30px;
  }
`;

const slideUp = keyframes`
  from {
    opacity: 0;
    bottom: -30px;
  }

  to {
    opacity: 1;
    bottom: 0;
  }
`;

const JumbotronPicture = styled.img`
  max-height: 90%;
  filter: drop-shadow(0px 0px 12px ${rgba(0, 0, 0, 0.5)});
  transition:
    bottom 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
  bottom: -30px;
  position: relative;

  ${Device.desktop} {
    height: auto;
    max-height: 440px;

    &:only-child {
      width: auto !important;
      padding: 30px;
    }
  }

  :only-child {
    transform: none !important;
    max-width: 100%;
  }

  :first-child {
    transform: rotate(-5deg);
    z-index: 1;
    animation: ${slideUp} 0.5s ease-in-out forwards;
  }

  :nth-child(2) {
    transform: rotate(5deg);
    z-index: 2;
    animation: ${slideUp} 0.5s ease-in-out forwards 250ms;
  }
`;

const DividingLine = styled.div`
  background: ${Colors.primaryLight};
  height: 5px;
  width: 100px;
  margin-top: -30px;
  margin-bottom: 15px;
`;
