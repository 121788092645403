import { Project } from '../model/Project';
import { ProjectKey } from '../model/enums/ProjectKey';
// import breviaryLogo from '../../assets/images/breviary-logo.svg';
import { ThemedCompanyLogo } from './ThemedCompanyLogo';
import React from 'react';
import { BreviaryLogo } from './BreviaryLogo';

export const ProjectLogo = ({ project, ...props }: { project?: Project; className?: string }) => {
  switch (project?.key) {
    case ProjectKey.Breviary:
      return <BreviaryLogo width={41} {...props} />;
    default:
      return <ThemedCompanyLogo {...props} />;
  }
};
