import styled from 'styled-components';
import { Button } from './Button';
import { rgba } from 'polished';
import { Colors } from '../../constants/colors';

export const OutlinedButton = styled(Button)`
  color: ${(props) => (props.inverse ? Colors.white : Colors.primary)};
  background: transparent;

  :hover {
    background: ${(props) => (props.inverse ? rgba(Colors.white, 0.1) : rgba(Colors.primary, 0.1))};
  }

  > span {
    flex: none;
  }

  > img {
    margin-left: 10px;
    max-height: 20px;
  }
`;
