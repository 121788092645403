import { useAppContext } from '../../state/StateProvider';
import { Container } from '../Container';
import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../constants/colors';
import { Device } from '../../constants/device';
import { Section } from '../Section';
import { ProjectSectionProps } from '../../model/ProjectSection';
import { ThemedFeatureIcon } from '../ThemedFeatureIcon';

type StyledProjectPointProps = {
  icon: string;
};

export const Features = (props: ProjectSectionProps): JSX.Element | null => {
  const {
    state: { project, sourceBranch },
  } = useAppContext();

  if (!project || !project.points.length) {
    return null;
  }

  const screenshot = `${process.env.REACT_APP_API_URL}/${sourceBranch}/images/screenshots/${process.env.REACT_APP_PROJECT}/${project.featureScreenshot}`;
  const iconSrc = `${process.env.REACT_APP_API_URL}/${sourceBranch}/images/icons`;

  return (
    <Section id="features" inverse={props.inverse}>
      <Container>
        <FeaturesSection>
          {project.featureScreenshot && (
            <StyledProjectScreenshot>
              <img src={screenshot} alt={project.name + ' ' + 'screenshot'} />
            </StyledProjectScreenshot>
          )}

          <StyledProjectPoints>
            {project.points.map((point, key) => (
              <StyledProjectPoint key={key} icon={iconSrc + '/' + point.icon}>
                <div className="content">
                  <ThemedFeatureIcon src={iconSrc + '/' + point.icon} alt={point.title} />
                  <h3>{point.title}</h3>
                  <p>{point.description}</p>
                </div>
              </StyledProjectPoint>
            ))}
          </StyledProjectPoints>
        </FeaturesSection>
      </Container>
    </Section>
  );
};

const FeaturesSection = styled.div`
  display: flex;
  margin-top: 80px;

  ${Device.laptop} {
    flex-direction: column;
    margin-top: 40px;
  }
`;

const StyledProjectScreenshot = styled.div`
  flex: 1;
  padding-right: 80px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${Device.laptop} {
    padding-right: 0;
    justify-content: center;
    padding-bottom: 40px;
  }

  img {
    width: 100%;
    max-width: 400px;

    ${Device.laptop} {
      width: auto;
      max-height: 400px;
    }
  }
`;

const StyledProjectPoints = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;

  flex: 2;
`;

const StyledProjectPoint = styled.div<StyledProjectPointProps>`
  margin: 30px;
  padding: 15px;

  flex: 1;
  flex-basis: calc(50% - 60px);
  width: calc(50% - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  text-align: center;

  ${Device.tablet} {
    flex-basis: 100%;
    width: 100%;
    margin: 0 0 20px;
  }

  .content {
    max-width: 320px;

    p {
      text-align: center !important;
    }
  }

  h3 {
    color: ${Colors.primary};
  }
`;
