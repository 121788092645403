import React from 'react';
import { useAppContext } from '../../state/StateProvider';
import { Container } from '../Container';
import appStoreBadgeEn from '../../../assets/images/download/app-store-badge-en.svg';
import appStoreBadgeCs from '../../../assets/images/download/app-store-badge-cs.svg';
import googlePlayBadgeEn from '../../../assets/images/download/google-play-badge-en.svg';
import googlePlayBadgeCs from '../../../assets/images/download/google-play-badge-cs.svg';
import styled from 'styled-components';
import { Device } from '../../constants/device';
import { Section } from '../Section';
import { useIntl } from 'react-intl';
import { AppLanguage } from '../../model/enums/AppLanguage';
import { ProjectSectionProps } from '../../model/ProjectSection';

export const Download = (props: ProjectSectionProps): JSX.Element | null => {
  const {
    state: { project },
  } = useAppContext();
  const { locale } = useIntl();

  if (!project || !(!!project.appStore || !!project.googlePlay)) {
    return null;
  }

  return (
    <Section id="download" inverse={props.inverse}>
      <Container compact={true}>
        <DownloadLinks>
          {!!project.googlePlay && (
            <a href={project.googlePlay} target="_blank" rel="noreferrer">
              <img src={locale === AppLanguage.Czech ? googlePlayBadgeCs : googlePlayBadgeEn} alt="AppStore badge" />
            </a>
          )}

          {!!project.appStore && (
            <a href={project.appStore} target="_blank" rel="noreferrer">
              <img src={locale === AppLanguage.Czech ? appStoreBadgeCs : appStoreBadgeEn} alt="GooglePlay badge" />
            </a>
          )}
        </DownloadLinks>
      </Container>
    </Section>
  );
};

const DownloadLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;

  ${Device.tablet} {
    flex-direction: column;
  }

  a {
    display: inline-block;
    width: 300px;
    max-width: 100%;
    margin: 0 30px;

    ${Device.tablet} {
      margin: 0 0 30px;
    }

    img {
      width: 100%;
    }
  }
`;
