import styled from 'styled-components';
import { Button } from './Button';
import { Colors } from '../../constants/colors';

export const InverseButton = styled(Button)`
  background: ${Colors.white};
  color: ${Colors.primary};
  border: 2px solid ${Colors.white};

  :hover {
    background: ${Colors.primaryLight};
  }
`;
