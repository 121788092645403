import styled from 'styled-components';
import { Device } from '../constants/device';

export const ThemedLogo = styled.div<{ width: number }>`
  width: ${(props) => `${props.width}px`};
  height: 60px;
  margin-right: 15px;

  ${Device.mobile} {
    width: 50px;
    height: 40px;
  }
`;
