import React from 'react';
import { useAppContext } from '../../state/StateProvider';
import { sanitize } from 'dompurify';
import { Container } from '../Container';
import { Section } from '../Section';
import styled from 'styled-components';
import { Colors } from '../../constants/colors';
import { ProjectSectionProps } from '../../model/ProjectSection';

export const About = (props: ProjectSectionProps): JSX.Element | null => {
  const {
    state: { project },
  } = useAppContext();

  if (!project || !project.descParts.length) {
    return null;
  }

  return (
    <Section id="about" inverse={props.inverse}>
      <Container compact={true}>
        {project.descParts.map((d, key) => (
          <AboutParagraph key={key} dangerouslySetInnerHTML={{ __html: sanitize(d) }} />
        ))}
      </Container>
    </Section>
  );
};

const AboutParagraph = styled.p`
  a {
    color: ${Colors.primary};
    font-weight: 700;
  }
`;
