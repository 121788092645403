import React from 'react';
import { useAppContext } from '../../state/StateProvider';
import { Container } from '../Container';
import { Section } from '../Section';
import styled from 'styled-components';
import { ProjectSectionProps } from '../../model/ProjectSection';

export const Youtube = (props: ProjectSectionProps): JSX.Element | null => {
  const {
    state: { project },
  } = useAppContext();

  if (!project || !project.youtubeId) {
    return null;
  }

  return (
    <Section id="youtube" inverse={props.inverse}>
      <Container>
        <StyledYoutube>
          {project.youtubeId.map((video, key) => (
            <iframe
              key={key}
              src={`https://www.youtube.com/embed/${video.id}`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={video.title}
            />
          ))}
        </StyledYoutube>
      </Container>
    </Section>
  );
};

const StyledYoutube = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  iframe {
    width: 100%;
    height: 360px;
    max-width: 640px;
  }
`;
