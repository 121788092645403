import { CompanyInterface } from '../model/CompanyInterface';
import axios from 'axios';
import { ProjectInterface } from '../model/ProjectInterface';

export const getProject = (company: CompanyInterface, sourceBranch: string): Promise<ProjectInterface> => {
  return axios
    .get<ProjectInterface>(`${process.env.REACT_APP_API_URL}/${sourceBranch}/${company.projects_dir}/${process.env.REACT_APP_PROJECT}.json`, {
      timeout: 4000,
    })
    .then((response) => {
      return { ...response.data, key: process.env.REACT_APP_PROJECT } as ProjectInterface;
    });
};
