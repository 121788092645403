import React, { ReactElement } from 'react';
import { ReactComponent as LogoBreviary } from '../../assets/images/breviary-logo.svg';
import { ThemedLogo } from './ThemedLogo';

type Props = {
  className?: string;
  width?: number;
};

export const BreviaryLogo = ({ className, width = 41 }: Props): ReactElement | null => (
  <ThemedLogo className={className} width={width}>
    <LogoBreviary />
  </ThemedLogo>
);
