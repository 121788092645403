import { LanguageStrings } from './en';

export const cs: LanguageStrings = {
  /** Sections **/
  'section.about': 'O projektu',
  'section.demo': 'Demo',
  'section.contact': 'Kontakt',
  'section.features': 'Funkce',
  'section.download': 'Ke stažení',
  'section.youtube': 'Video',

  /** Sections content */
  'section.demo.desc': 'Nečekejte.\nVyzkoušejte naše demo.',

  /** Common */
  error: 'Jejda, něco se pokazilo...',
  'error.button': 'Znovu načíst stránku',

  'mild-blue.coop': 'Vytvářeno ve spolupráci s',
};
