import { ProjectBulletPointInterface, ProjectInterface } from '../../model/ProjectInterface';
import { Project, ProjectBulletPoint } from '../../model/Project';
import { AppLanguage } from '../../model/enums/AppLanguage';

export const translateProject = (data: ProjectInterface, locale: string): Project => {
  const points = data.points ? data.points.map((p) => translateProjectPoint(p, locale)) : [];

  if (locale === AppLanguage.Czech) {
    return {
      ...data,
      name: data.name_cs ?? '',
      subtitle: data.subtitle_cs ?? '',
      descParts: data.desc_pieces_cs ?? [],
      contactNote: data.contact_note_cs,
      points,
    };
  }

  return {
    ...data,
    name: data.name_en ?? '',
    subtitle: data.subtitle_en ?? '',
    descParts: data.desc_pieces_en ?? [],
    contactNote: data.contact_note_en,
    points,
  };
};

export const translateProjectPoint = (data: ProjectBulletPointInterface, locale: string): ProjectBulletPoint => {
  if (locale === AppLanguage.Czech) {
    return {
      title: data.title_cs,
      description: data.description_cs,
      ...data,
    };
  }

  return {
    title: data.title_en,
    description: data.description_en,
    ...data,
  };
};
