import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import { useAppContext } from './state/StateProvider';
import { getCompany } from './services/company.service';
import { getProject } from './services/project.service';
import { useIntl } from 'react-intl';
import { translateProject } from './i18n/translators/project.translator';
import { ProjectInterface } from './model/ProjectInterface';
import { CompanyInterface } from './model/CompanyInterface';
import { translateCompany } from './i18n/translators/company.translator';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Loading } from './components/Loading';
import { Timings } from './constants/timings';
import { ProjectSection } from './model/ProjectSection';
import { setGlobalCompany, setGlobalProject, setSections } from './state/Action';

export const AppLayout = ({ children }: { children: React.ReactNode }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [company, setCompany] = useState<CompanyInterface | undefined>(undefined);
  const [project, setProject] = useState<ProjectInterface | undefined>(undefined);
  const { state, dispatch } = useAppContext();
  const { locale } = useIntl();

  /** Get company on first load **/
  useEffect(() => {
    setLoading(true);

    getCompany(state.sourceBranch)
      .then((company) => {
        // Set original company locally
        setCompany(company);
        // Set translated company globally
        dispatch(setGlobalCompany(translateCompany(company, locale)));
      })
      .catch(() => setLoading(false));
  }, []);

  /** Get project on company change **/
  useEffect(() => {
    if (!company) {
      return;
    }

    getProject(company, state.sourceBranch)
      .then((project) => {
        // Set original project locally
        setProject(project);
        // Set translated project globally
        dispatch(setGlobalProject(translateProject(project, locale)));
        // Init GA
        if (project.ga?.length) {
          ReactGA.initialize(project.ga);
        }
        // Init Facebook domain verification
        if (project.facebook_domain_verification?.length) {
          const meta = document.createElement('meta');
          meta.name = 'facebook-domain-verification';
          meta.content = project.facebook_domain_verification;
          document.getElementsByTagName('head')[0].appendChild(meta);
        }
        // Init Meta Pixel
        if (project.pixel?.length) {
          ReactPixel.init(project.pixel);
          ReactPixel.pageView();
        }
      })
      .finally(() => setTimeout(() => setLoading(false), Timings.LoadingTimeout));
  }, [company]);

  /** Set active sections on project change **/
  useEffect(() => {
    if (!state.project) {
      return;
    }

    const activeSections: ProjectSection[] = [];

    if (state.project.descParts.length) {
      activeSections.push('about');
    }

    if (state.project.points.length) {
      activeSections.push('features');
    }

    if (state.project.youtubeId) {
      activeSections.push('youtube');
    }

    if (!!state.project.appStore || !!state.project.googlePlay) {
      activeSections.push('download');
    }

    if (state.project.demo) {
      activeSections.push('demo');
    }

    if (!!state.project.email || !!state.company?.email) {
      activeSections.push('contact');
    }

    dispatch(setSections(activeSections));
  }, [state.project]);

  /** Localize company and project on locale change **/
  useEffect(() => {
    if (company) {
      // Set translated company globally
      dispatch(setGlobalCompany(translateCompany(company, locale)));
    }
    if (project) {
      // Set translated project globally
      const translatedProject = translateProject(project, locale);
      dispatch(setGlobalProject(translatedProject));

      // Change page title
      document.title = `${translatedProject.name}: ${translatedProject.subtitle} | Mild Blue`;
    }
  }, [locale, project]);

  return (
    <>
      <Loading hide={!loading && state.project !== undefined} error={!loading && state.project === undefined} />
      {(() => {
        if (!loading && state.project !== undefined) {
          return (
            <>
              <Header />
              {children}
              <Footer />
            </>
          );
        }
      })()}
    </>
  );
};
