import React from 'react';
import { useAppContext } from '../state/StateProvider';
import { Jumbotron } from './Jumbotron';
import { About } from './sections/About';
import { Features } from './sections/Features';
import { Download } from './sections/Download';
import { Demo } from './sections/Demo';
import { Contact } from './sections/Contact';
import { Youtube } from './sections/Youtube';

export const Home: React.FC = () => {
  const {
    state: { project, activeSections },
  } = useAppContext();

  if (!project) {
    return null;
  }

  let currentSectionIndex = 0;
  return (
    <main>
      <Jumbotron />
      {activeSections.map((section) => {
        const inverse = currentSectionIndex % 2 > 0;
        currentSectionIndex++;

        switch (section) {
          case 'about':
            return <About key={section} inverse={inverse} />;
          case 'features':
            return <Features key={section} inverse={inverse} />;
          case 'youtube':
            return <Youtube key={section} inverse={inverse} />;
          case 'download':
            return <Download key={section} inverse={inverse} />;
          case 'demo':
            return <Demo key={section} />;
          case 'contact':
            return <Contact key={section} inverse={inverse} />;
        }
      })}
    </main>
  );
};
