import React, { ReactElement, useEffect, useState } from 'react';
import { Colors } from '../constants/colors';
import axios from 'axios';

type Props = {
  src: string;
  alt: string;
};

export const ThemedFeatureIcon = (props: Props): ReactElement | null => {
  const [imageContent, setImageContent] = useState('');
  const [iconWidth, setIconWidth] = useState<string>('512');
  const [iconHeight, setIconHeight] = useState<string>('512');

  useEffect(() => {
    axios
      .get(props.src)
      .then((response) => {
        let content = response.data;
        const widthMatch = content.match(/width="([^"]+)"/);
        const heightMatch = content.match(/height="([^"]+)"/);
        if (widthMatch) {
          setIconWidth(widthMatch[1] || widthMatch);
        }
        if (heightMatch) {
          setIconHeight(heightMatch[1] || heightMatch);
        }
        content = content.replace(/#273E98/g, Colors.primary); // Some icons have blue color hardcoded into them
        setImageContent(content);
      })
      .catch((error) => {
        console.error('Error fetching SVG:', error);
      });
  }, [props.src]);

  return (
    <div className={props.alt} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${iconWidth} ${iconHeight}`} dangerouslySetInnerHTML={{ __html: imageContent }} style={{ fill: Colors.primary, height: '80px' }} />
    </div>
  );
};
