import React, { createContext, useContext, useReducer } from 'react';
import { Reducer } from './Reducer';
import { Project } from '../model/Project';
import { Company } from '../model/Company';
import { ProjectSection } from '../model/ProjectSection';
import { Action, setSourceBranch } from './Action';

/**
 * State
 */

export interface AppState {
  company?: Company;
  project?: Project;
  sourceBranch: string;
  activeSections: ProjectSection[];
}

const initialState: AppState = {
  activeSections: [],
  sourceBranch: process.env.REACT_APP_DEFAULT_BRANCH_SOURCE,
};

/**
 * Context
 */

type AppContextType = {
  state: AppState;
  dispatch: React.Dispatch<Action>;
};

const AppContext = createContext<AppContextType>({
  state: initialState,
  dispatch: () => null,
});

export const useAppContext = (): AppContextType => useContext(AppContext);

/**
 * Provider
 */

export const StateProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  if (!state.sourceBranch) {
    console.error('Please set variable REACT_APP_DEFAULT_BRANCH_SOURCE in .env');
  }

  // If query is set, rewrite the initial value from env
  const queryParams = new URLSearchParams(window.location.search);
  const source = queryParams.get('source');
  if (source && source !== state.sourceBranch) {
    dispatch(setSourceBranch(source));
  }

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};
